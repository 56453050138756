<template>
    <div>
        <div style="background:#D8C2AA;padding:10px;">
            <div style="background:#fff;border-radius:10px;padding:30px">
                <div style="text-align:center;">
                    <img src="${logo_path}" style="width:122px;" alt="">
                </div>

                <div style="height:30px;"></div>
                
                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">商品名称</div>    
                    <div style="font-size:12px;color:#524037;">${goods_name}</div>
                </div>

                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;margin-top:15px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">证书号</div>    
                    <div style="font-size:12px;color:#524037;">${template_attr_112}</div>
                </div>

                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;margin-top:15px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">总质量</div>    
                    <div style="font-size:12px;color:#524037;">${template_attr_110}g</div>
                </div>

                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;margin-top:15px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">主石名称</div>    
                    <div style="font-size:12px;color:#524037;">${template_attr_120}</div>
                </div>

                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;margin-top:15px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">石重</div>    
                    <div style="font-size:12px;color:#524037;">${template_attr_130}g</div>
                </div>

                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;margin-top:15px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">颜色</div>    
                    <div style="font-size:12px;color:#524037;">${template_attr_123}</div>
                </div>

                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;margin-top:15px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">净度</div>    
                    <div style="font-size:12px;color:#524037;">${template_attr_124}</div>
                </div>

                <div style="display:flex;border-bottom:1px solid #DADBD7;padding-bottom:12px;margin-top:15px;">
                    <div style="font-size:12px;color:#524037;font-weight:600;width:80px;">货号</div>    
                    <div style="font-size:12px;color:#524037;">${goods_number}</div>
                </div>

                <div style="text-align:center;margin-top:15px;">
                    <img src="${goods_pic}" style="width:120px;height:120px;object-fit: cover;" alt="">
                </div>

                <div style="text-align:center;font-size:12px;color:#524037;">
                    <div>${notice}</div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{

        }
    }
}
</script>

<style lang='less' scoped>

</style>