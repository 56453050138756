import { get, post } from '@/utils/request';

const getList = (data) => post({
    url: '/store/goods.Certificate/list',
    data,
  });

const getDetail=(data)=>post({
    url:'/store/goods.Certificate/details',
    data,
})

const saveElectronic=(data)=>post({
    url:'/store/goods.Certificate/save',
    data,
})

const deleteList=(data)=>post({
    url:'/store/goods.Certificate/delete',
    data,
})

// 更新状态
const updateStatus=(data)=>post({
    url:'/store/goods.Certificate/updateStatus',
    data,
})

// 复制证书
const copyElect=data=>post({
    url:'/store/goods.Certificate/copy',
    data,
})

export {
    copyElect,
    updateStatus,
    deleteList,
    saveElectronic,
    getDetail,
    getList,
};