<template>
  <div class="wrapper">
    <div class="left">
      <el-form :model="form" label-width="150px" :rules="rules" ref="ruleForm">

        <el-form-item label="模板名称"  prop="title">
            <el-input placeholder="请输入模板名称" v-model="form.title" /> 
        </el-form-item>

        <el-form-item label="模板链接"  prop="jump_url">
            <el-input placeholder="" v-model="form.jump_url" />
        </el-form-item>

        <el-form-item label="上传logo"  v-loading="uploadIngLogo" class="empty" required prop="logo_path">
          <el-upload
            class="avatar-uploader"
            action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
            :data="{
              directory: 'WARRANTY_LOGO'
            }"
            :headers="{
                authorization: token
            }"
            :show-file-list="false"
            list-type="picture-card"
            :on-success="handleLogoUrlSuccess"
            :before-upload="beforeLogoUpload"
          >
            <img
              v-if="form.logo_path"
              :src="form.logo_path"
              style="object-fit: contain;"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span @click="form.logo_path=''">重置</span>
        </el-form-item>

        <!-- <el-form-item label="上传背景" required v-loading="upBgloadIng" class="empty" prop="background_path">
          <el-upload
            class="avatar-uploader"
            action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
            :data="{
              directory: 'WARRANTY_SEAL'
            }"
            :headers="{
                authorization: token
            }"
            :show-file-list="false"
            list-type="picture-card"
            :on-success="handBgUrlSuccess"
            :before-upload="beforeBgUpload"
          >
            <img
              v-if="form.background_path"
              :src="form.background_path"
              style="object-fit: contain;"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span @click="form.background_path=''">重置</span>
        </el-form-item> -->

        <el-form-item label="应用门店" required>
            <el-radio v-model="radio1" label="1">全部门店</el-radio>
            <el-radio v-model="radio1" label="2">指定门店</el-radio>
            <template v-if="radio1==2">
                <el-checkbox style="display:block;" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities">
                  <!--  @change="handleCheckedCitiesChange" -->
                    <el-checkbox v-for="(item,index) in detailObj.hierarchy" @change="handleCheckedCitiesChange(index)" :label="item.id" :key="item.id" :disabled="!item.isEnable" :checked="item.select">{{item.name}}</el-checkbox>
                </el-checkbox-group>
            </template>
        </el-form-item>

         <el-form-item label="应用分类" required>
            <el-radio v-model="radio2" label="1">全部分类</el-radio>
            <el-radio v-model="radio2" label="2">指定分类</el-radio>
            <template v-if="radio2==2">
                <el-checkbox style="display:block;" :indeterminate="isIndeterminate2"  @change="handleCheckAllChange2">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities2" >
                    <el-checkbox v-for="(item,index) in detailObj.attr" @change="handleCheckedCitiesChange2(index)" :label="item.id" :key="item.id" :disabled="!item.isEnable" :checked="item.select">{{item.name}}</el-checkbox>
                </el-checkbox-group>
            </template>
        </el-form-item>

         <el-form-item label="应用品牌" required>
            <el-radio v-model="radio3" label="1">全部品牌</el-radio>
            <el-radio v-model="radio3" label="2">指定品牌</el-radio>
            <template v-if="radio3==2">
                <el-checkbox style="display:block;" :indeterminate="isIndeterminate3"  @change="handleCheckAllChange3">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities3">
                    <el-checkbox v-for="(item,index) in detailObj.brand"  @change="handleCheckedCitiesChange3(index)" :label="item.id" :key="item.id" :disabled="!item.isEnable" :checked="item.select">{{item.name}}</el-checkbox>
                </el-checkbox-group>
            </template>
        </el-form-item>

        <el-form-item label="显示商品图">
            <el-switch v-model="form.is_show_goods_img" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>

        <el-form-item label="模板内容" prop="content">
          <el-input type="textarea"  show-word-limit :rows="10" v-model="form.content"></el-input>
        </el-form-item>

        <el-form-item label="底部备注">
          <el-input type="textarea"  :maxlength="500" show-word-limit :rows="10" v-model="form.notice"></el-input>
        </el-form-item>

        <div style="text-align:center">
          <el-button type="danger" @click="resetQulity" :loading="uploadIng" style="margin-right:15px">重置</el-button>
          <el-button type="primary" v-if="queryObj.istype!='copy'" @click="saveElectronic('ruleForm')" :loading="uploadIng" style="margin-left:15px">保存</el-button>
          <el-button type="primary" v-else @click="copyElect" :loading="uploadIng" style="margin-left:15px">复制</el-button>
        </div>
      </el-form>

    </div>
    <div class="right" style="position:absolute;top:100px;right:400px;">
      <div class="title" style="margin-bottom:20px;">电子证书预览效果</div>
      <div >
        <img src="@/assets/images/marketing/message/iphone.png" style="width:300px;height:600px;" alt="">
        <div v-html="content_html" style="position:absolute;top:115px;left:8px;height:500px;overflow:auto;width:285px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail,saveElectronic,copyElect} from '@/api/system/electronic';
import preview from './preview.vue'
export default{
    components:{
        preview,
    },
    data(){
        return{
            rules:{
              title:[{ required: true, message: '请输入模板名称', trigger: 'blur' },],
              jump_url:[{ required: true, message: '请输入模板链接', trigger: 'blur' }],
              logo_path:[{ required: true, message: '请上传logo', trigger: 'input' }],
              // background_path:[{required:true,message:'请上传背景图片',trigger:'input'}],
              content:[{required:true,message:'请输入模板内容',trigger:'input'}],
            },
            radio1:'1',
            radio2:'1',
            radio3:'1',
            uploadIng:false,
            token: '',
            form:{
               title:'',
               jump_url:'',
               logo_path:'',  //logo
              //  background_path:'',//背景图片
               is_show_goods_img:'',
               content:'',
               notice:'',
            },
            upBgloadIng:false, //关闭loading
            uploadIngLogo:false,

            checkAll: false,
            checkedCities: [],
            checkedCities2:[],
            checkedCities3:[],
            isIndeterminate: false,
            isIndeterminate2:false,
            isIndeterminate3:false,
            detailObj:{},

            oldListAll:[],
            paramsObj:{},
            queryObj:{},
            indexArr:[],
            indexArr2:[],
            indexArr3:[],
            detailObj2:{},

            content_html:'',
        }
    },
    mounted(){
        this.paramsObj=this.$route.params
        this.queryObj=this.$route.query
        this.getDetail()
    },
    methods:{

      // 复制模板
      copyElect(){
        copyElect({
          certificate_set_id:this.paramsObj.id
        }).then(res=>{
          if(res.code==1){
            this.$message({
              type:'success',
              message:'复制模板成功',
            })
          }
        })
      },

      //重置
      resetQulity(){
       this.$confirm('此操作将清空数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           this.$refs['ruleForm'].resetFields();
           this.form.notice=''
           this.checkedCities=this.checkedCities2=this.checkedCities3=[]
           this.$message({
              type: 'success',
              message: '重置成功'
           });
        }).catch(() => {});
      },

      // 新增
      saveElectronic(formName){
        this.$refs[formName].validate((valid)=>{
          if(valid){
              let hierarchy = []
              this.detailObj.hierarchy.forEach(it =>{
                if (it.select) {
                  hierarchy.push(it.id)
                }
              })
              let attr = []
              this.detailObj.attr.forEach(it =>{
                if (it.select) {
                  attr.push(it.id)
                }
              })
              let brand = []
              this.detailObj.brand.forEach(it =>{
                if (it.select) {
                  brand.push(it.id)
                }
              })
              saveElectronic({
                title:this.form.title,
                jump_url:this.form.jump_url,
                certificate_set_id:this.paramsObj.id?this.paramsObj.id:0,
                hierarchy_ids:this.radio1=='1'?0:hierarchy.toString(),
                attr_value_ids:this.radio2=='1'?0:attr.toString(),
                brand_ids:this.radio3=='1'?0:brand.toString(),
                notice:this.form.notice,
                logo_path:this.form.logo_path,
                content:this.form.content,
                background_path:'',
                is_show_goods_img:this.form.is_show_goods_img,
              }).then(res=>{
                if(res.code==1){
                  this.getDetail()
                  if(!this.paramsObj.id){
                    document.closetab();
                  }
                  this.$bus.$emit('electronicUpdateList')
                  this.$message({
                    message:'保存成功',
                    type:'success'
                  })
                }
              })
          }else{
            console.log('erroe')
            return false;
          }
        })
      },

        //获取详情
        getDetail(){
          getDetail({
            certificate_set_id:this.paramsObj.id?this.paramsObj.id:0,
          }).then(res=>{
            if(res.code==1){
                this.detailObj=res.data
                this.detailObj.oldListAll.forEach(element => {
                      this.oldListAll.push(element.hierarchy_id + "-" + element.attr_id + "-" + element.brand_id)
                });
                this.detailObj2=JSON.parse(JSON.stringify(this.detailObj))
                this.form.content=res.data.details.content
                this.content_html=res.data.details.content_html
                //详情
                if(this.paramsObj.id){
                  this.form.title=res.data.details.title
                  this.form.jump_url=res.data.details.jump_url
                  this.form.logo_path=res.data.details.logo_path
                  // this.form.background_path=res.data.details.background_path
                  this.form.status=res.data.details.status
                  this.form.content=res.data.details.content
                  this.form.notice=res.data.details.notice
                  this.radio1=res.data.details.hierarchy_ids[0]==0?'1':'2'
                  this.radio2=res.data.details.attr_value_ids[0]==0?'1':'2'
                  this.radio3=res.data.details.brand_ids[0]==0?'1':'2'
                  this.form.is_show_goods_img=res.data.details.is_show_goods_img
                }
                // this.watchDisa()
            }
          })
        },

        //背景
        beforeLogoUpload(){
            this.uploadIngLogo=true
        },

        //打开背景图上传加载
        beforeAvatarUpload() {
            this.uploadIng = true;
            console.log(this.uploadIng);
        },

         beforeBgUpload(){

         },

        //  上传背景
         handBgUrlSuccess(response){
            if (response.code === 1) {
              this.upBgloadIng = false;
              this.form.background_path = response.data;
              // this.saveQulity()
            } else {upBgloadIng
            this.uploadIng = false;
              this.$message.error(response.msg);
            }
         },

        // 上传logo
        handleLogoUrlSuccess(response){
            if (response.code === 1) {
              this.uploadIngLogo = false;
              this.form.logo_path = response.data;
              // this.saveQulity()
            } else {
              this.uploadIngLogo = false;
              this.$message.error(response.msg);
            }
        },

      
        handleCheckAllChange(val) {
            if(val){
              this.detailObj.hierarchy.forEach((item,index)=>{
                this.checkedCities.push(item.id)
                item.select=true
              })
            }else{
              this.checkedCities=[]
              this.detailObj.hierarchy.forEach((item,index)=>{
                item.select=false
              })
            }
        },

        handleCheckAllChange2(val) {
            if(val){
              this.detailObj.attr.forEach((item,index)=>{
                this.checkedCities2.push(item.id)
                item.select=true
              })
            }else{
              this.checkedCities2=[]
              this.detailObj.hierarchy.forEach((item,index)=>{
                item.select=false
              })
            }
        },

         handleCheckAllChange3(val) {
           if(val){
              this.detailObj.brand.forEach((item,index)=>{
                this.checkedCities3.push(item.id)
                item.select=true
              })
            }else{
              this.checkedCities3=[]
              this.detailObj.brand.forEach((item,index)=>{
                item.select=false
              })
            }
        },


        //监听是否禁用
        watchDisa(){
            this.detailObj.hierarchy.forEach((hv,index) => {
                if (!hv.select) {
                    hv.isEnable = this.detailObj2.hierarchy[index].isEnable
                    this.detailObj.attr.forEach(av => {
                      if (av.select) {
                        this.detailObj.brand.forEach(bv => {
                              if (bv.select) {
                                let str = hv.id  + "-" +  av.id + "-" +  bv.id
                                if (this.oldListAll.indexOf(str) != -1) {
                                  //  console.log(str)
                                    hv.select ?  hv.isEnable = true :  hv.isEnable = false
                                }
                              }
                        })
                      }
                })
                }
            });
          
            this.detailObj.attr.forEach((av,index) => {
                  if (!av.select) {
                      // av.isEnable = true
                      av.isEnable = this.detailObj2.attr[index].isEnable
                      this.detailObj.hierarchy.forEach(hv => {
                        if (hv.select) {
                          this.detailObj.brand.forEach(bv => {
                              if(bv.select){
                                  let str = hv.id  + "-" +  av.id + "-" +  bv.id
                                  if (this.oldListAll.indexOf(str) != -1) {
                                      av.select ?  av.isEnable = true :  av.isEnable = false
                                  }
                              }
                          })
                        }
                  })
              };
          })

          this.detailObj.brand.forEach((bv,index) => {
              if (!bv.select) {
                    // bv.isEnable = true
                    bv.isEnable = this.detailObj2.brand[index].isEnable
                    this.detailObj.hierarchy.forEach(hv => {
                      if (hv.select) {
                          this.detailObj.attr.forEach(av => {
                            if(av.select){
                              let str = hv.id  + "-" +  av.id + "-" +  bv.id
                              if (this.oldListAll.indexOf(str) != -1) {
                                  bv.select ?  bv.isEnable = true :  bv.isEnable = false 
                              }
                            }
                          })
                      }
                  })
              };
          })
        },

        // 监听值改变
        handleCheckedCitiesChange3(idx){
            let index= this.indexArr3.indexOf(idx)
            if(index==-1){
                this.indexArr3.push(idx)
                this.detailObj.brand[idx].select=true
                console.log( this.indexArr3)
            }else{
              this.indexArr3.splice(index,1)
              this.detailObj.brand[idx].select=false
              console.log( this.indexArr3)
            }
            // let index;
            // this.detailObj.brand.filter((item,idx)=>{
            //     if(item.id==value[value.length-1]){
            //       index=idx
            //       return true;
            //     }
            // })
            // this.detailObj.brand[index].select=true
            this.watchDisa()
        },


        handleCheckedCitiesChange2(idx){
            let index= this.indexArr2.indexOf(idx)
            if(index==-1){
                this.indexArr2.push(idx)
                this.detailObj.attr[idx].select=true
                console.log( this.indexArr2)
            }else{
              this.indexArr2.splice(index,1)
              this.detailObj.attr[idx].select=false
              console.log( this.indexArr2)
            }
            // let index;
            // this.detailObj.attr.filter((item,idx)=>{
            //     if(item.id==value[value.length-1]){
            //       index=idx
            //       return true;
            //     }
            // })
            // this.detailObj.attr[index].select=true
            this.watchDisa()
        },

        handleCheckedCitiesChange(idx) {
            let index= this.indexArr.indexOf(idx)
            if(index==-1){
                this.indexArr.push(idx)
                this.detailObj.hierarchy[idx].select=true
                console.log( this.indexArr)
            }else{
              this.indexArr.splice(index,1)
              this.detailObj.hierarchy[idx].select=false
              console.log( this.indexArr)
            }
           
            // let index;
            // console.log(value)
            //  this.detailObj.hierarchy.filter((item,idx)=>{
            //     if(item.id==value[value.length-1]){
            //       index=idx
            //       return true;
            //     }
            // })
            // this.detailObj.hierarchy[index].select=true
            this.watchDisa()

      },



    }
}
</script>

<style lang="less" scoped>
.empty{
  .avatar-uploader{
    display: inline-block;
    vertical-align: top;
  }
  span{
    line-height: 160px;
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
    color: #007BFF;
    cursor:pointer;
  }
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
.wrapper {
  width: 100%;
  // height: calc(100vh - 85px);
  background: white;
  padding: 20px;
  box-sizing: border-box;
  .left {
    margin-top: 43px;
    width: 40%;
    border: 1px solid #ccc;
    padding: 30px 40px 30px 10px;
  }
  .right {
    flex: 1;
    // margin-left: 150px;
    .title {
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
